import axios from 'axios';

import { msalInstance } from '@/helpers/msal';

class Unlock3DLiteService {
  async checkView3DModelAvailability(
    documentNo: string,
    documentType: string,
    projectCode: string,
  ) {
    if (documentType !== 'DP - PFD-PID DIAGRAMS') {
      return null;
    }
    try {
      const response = await this.urlLookup(documentNo, projectCode);
      if (response.status === 200) {
        return response.data.url;
      }
    } catch (error: any) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        return null;
      } else {
        console.error('Error checking 3D model availability', error);
      }
    }
    return null;
  }

  async getAccessTokenAsync(): Promise<string> {
    const userAccount = msalInstance.getActiveAccount()!;

    const tokenRequest = {
      scopes: [process.env.REACT_APP_U3D_API_SCOPE ?? ''],
    };

    try {
      const res = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account: userAccount,
      });
      return res.accessToken;
    } catch (error) {
      console.error(error);
      const res = await msalInstance.acquireTokenPopup({
        ...tokenRequest,
        redirectUri: '/blank.html',
      });
      return res.accessToken;
    }
  }

  async urlLookup(documentNo: string, projectCode: string) {
    const accessToken = await this.getAccessTokenAsync();
    const response = await axios.get(
      `${process.env.REACT_APP_U3D_BASE_API_URL}/external/url_lookup/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          key: ':PID',
          project_code: projectCode,
          value: documentNo,
        },
      },
    );
    return response;
  }
}

const unlock3DLiteService = new Unlock3DLiteService();
export default unlock3DLiteService;
