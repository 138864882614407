import Fab from '@mui/material/Fab';
import { useSnackbar } from 'notistack';
import React from 'react';

import customIcon from '@/assets/icons/persp.svg';

interface View3DModelButtonProps {
  modelUrl: string;
}

const View3DModelButton: React.FC<View3DModelButtonProps> = ({ modelUrl }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleView3DModel = () => {
    if (modelUrl) {
      window.open(modelUrl, '_blank');
    } else {
      enqueueSnackbar('3D model not available', { variant: 'error' });
    }
  };

  return (
    <Fab
      onClick={handleView3DModel}
      size="large"
      sx={{
        '&:hover': {
          backgroundColor: '#2C47CC',
        },
        backgroundColor: '#3F5BFF',
        borderRadius: '100px',
        bottom: '60px',
        boxShadow:
          '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14)', // Matches elevation/6
        color: 'white',
        fontFamily: 'Noto Sans, sans-serif',
        height: '56px',
        position: 'fixed',
        right: '60px',
        textTransform: 'none',
        width: '144px',
      }}
      variant="extended"
    >
      <img
        alt="Custom Icon"
        src={customIcon}
        style={{
          marginRight: '8px',
        }}
      />
      View in 3D
    </Fab>
  );
};

export default View3DModelButton;
